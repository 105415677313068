<template>
  <ValidationObserver v-slot="{ invalid }">
    <div class="vx-row px-8 relative">
      <section class="vx-col w-8/12 pr-10">
        <slot name="complementoConteudoProposta"></slot>
        <div class="vx-row px-2">
          <div class="w-1/2 px-2">
            <p class="flex font-semibold">Grupo modalidades</p>
            <ValidationProvider
              name="Grupo de modalidades"
              rules="required"
              v-slot="{ errors }"
            >
              <v-select
                label="Name"
                class="w-full"
                :options="modalidades"
                :show-no-options="false"
                :show-no-results="false"
                :value="selectedGrupo"
                v-model="selectedGrupo"
                @input="grupoModalidadeSelecionado(selectedGrupo)"
                placeholder="Selecione uma opção"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
              />
              <span class="text-danger text-sm" v-show="errors[0]">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </div>

          <div class="w-1/2 px-2">
            <p class="flex font-semibold">
              Modalidade principal
              <!-- <vx-tooltip :text="text.textModalidadePrincipal" position="right">
                <i class="material-icons pl-2">info</i>
              </vx-tooltip> -->
            </p>
            <p class="mt-4" v-if="!(selectedGrupo || {}).Id">
              Selecione um grupo de modalidades
            </p>
            <ValidationProvider
              name="Modalidade principal"
              rules="required"
              v-slot="{ errors }"
            >
              <v-select
                label="Name"
                class="w-full select"
                v-if="(selectedGrupo || {}).Id"
                :show-no-options="false"
                @input="modalidadeSelecionada(selectedModalidadePrincipal)"
                :options="modalidadePrincipal"
                v-model="selectedModalidadePrincipal"
                placeholder="Selecione uma opção"
                :dir="$vs.rtl ? 'rtl' : 'ltr'"
              />
              <span class="text-danger text-sm" v-show="errors[0]">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </div>
        </div>

        <div class="vx-row mt-5 px-2">
          <div class="w-1/4 pl-2 pr-1">
            <p class="flex font-semibold">
              Importância segurada
              <!-- <vx-tooltip :text="text.textImportanciaSegurada" position="right">
                <i class="material-icons pl-1">info</i>
              </vx-tooltip> -->
            </p>
            <ValidationProvider
              name="Importância segurada"
              :rules="
                `required|minInsurancePremium:${
                  (selectedModalidadePrincipal || {}).MinInsurancePremium
                },${
                  (selectedModalidadePrincipal || {}).Name
                },${percLimit}|maxInsurancePremium:${
                  (selectedModalidadePrincipal || {}).MaxInsuredAmountValue
                },${(selectedModalidadePrincipal || {}).Name}`
              "
              v-slot="{ errors }"
            >
              <currency-input
                v-model="importanciaSegurada"
                @blur="calculoValorPremio(1)"
                class="w-full vs-inputx vs-input--input large hasValue"
              />
              <span class="text-danger text-sm" v-show="errors[0]">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </div>

          <div class="w-1/4 pl-3 pr-2">
            <p class="font-semibold">Inicio vigência</p>
            <ValidationProvider
              name="Data de início"
              rules="required"
              v-slot="{ errors }"
            >
              <datepicker
                :language="ptBR"
                class="w-full"
                format="dd/MM/yyyy"
                v-model="vigenciaInicioPrincipal"
                @input="calculoDiasVigenciaPrincipal()"
                :disabled-dates="disabledDatesInicio()"
              />
              <span class="text-danger text-sm" v-show="errors[0]">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </div>

          <div class="w-1/4 px-2">
            <p class="font-semibold">Prazo dias</p>
            <ValidationProvider
              name="Prazo em dias"
              rules="required"
              v-slot="{ errors }"
            >
              <vs-input
                class="w-full"
                size="large"
                v-model.number="vigenciaDiasPrincipal"
                @change="calculoDataFimVigenciaPrincipal()"
              />
              <span class="text-danger text-sm" v-show="errors[0]">
                {{ errors[0] }}
              </span>
            </ValidationProvider>
          </div>

          <div class="w-1/4 px-2">
            <p class="font-semibold">Final vigência</p>
            <ValidationProvider
              name="Data fim"
              rules="required"
              v-slot="{ errors }"
            >
              <vs-input
                v-if="isDisabledEnd"
                class="w-full"
                size="large"
                v-model="vigenciaFimPrincipal"
                disabled="true"
              />
              <datepicker
                v-else
                :language="ptBR"
                class="w-full"
                format="dd/MM/yyyy"
                v-model="vigenciaFimPrincipal"
                @input="calculoDiasVigenciaPrincipal()"
                :disabled-dates="disabledDatesFim()"
              />

              <span
                class="text-danger text-sm"
                v-show="errors[0] || showErroData"
              >
                {{ errors[0] || erroData }}
              </span>
            </ValidationProvider>
          </div>
        </div>

        <template
          v-if="(selectedModalidadePrincipal || {}).HasComplementaryModality"
        >
          <div class="vx-row mt-8 pl-2">
            <div class="w-1/2 px-2">
              <p class="font-semibold">Modalidade complementar</p>
              <ValidationProvider
                name="Modalidade complementar"
                v-slot="{ errors }"
              >
                <vs-input
                  v-if="isDisabledComplementary"
                  class="w-full"
                  size="large"
                  :value="'Aguardando modalidade principal...'"
                  disabled="true"
                />
                <v-select
                  v-else
                  label="Name"
                  class="w-full"
                  :options="modalidadeComplementar"
                  :show-no-results="false"
                  v-model="selectedModalidadeComplementar"
                  @input="modalidadeComplementarSelecionada(selectedModalidadeComplementar)"
                  placeholder="Selecione um opção"
                  :dir="$vs.rtl ? 'rtl' : 'ltr'"
                />
                <span class="text-danger text-sm" v-show="errors[0]">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
          </div>

          <div
            class="vx-row mt-5 pl-2"
            v-if="(selectedModalidadeComplementar || {}).Id > 0"
          >
            <div class="w-1/4 pl-2 pr-1">
              <p class="flex font-semibold">
                Importância segurada
                <!-- <vx-tooltip
                  :text="text.textImportanciaSegurada"
                  position="right"
                >
                  <i class="material-icons pl-2">info</i>
                </vx-tooltip> -->
              </p>
              <ValidationProvider
                name="Importância segurada complementar"
                :rules="{
                  required: (selectedModalidadeComplementar || {}).Id > 0
                }"
                v-slot="{ errors }"
              >
                <currency-input
                  @blur="calculoValorPremio(2)"
                  v-model="importanciaSeguradaComplementar"
                  class="w-full vs-inputx vs-input--input large hasValue"
                />
                <span class="text-danger text-sm" v-show="errors[0]">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>

            <div class="w-1/4 pl-3 pr-2">
              <p class="font-semibold">Inicio vigência</p>
              <ValidationProvider
                name="Inicio da vigência complementar"
                :rules="{
                  required: (selectedModalidadeComplementar || {}).Id > 0
                }"
                v-slot="{ errors }"
              >
                <datepicker
                  :language="ptBR"
                  format="dd/MM/yyyy"
                  class="w-full"
                  :disabled="desabilitaInicioVigenciaComplementar()"
                  v-model="vigenciaInicioComplementar"
                  @input="calculoDiasVigenciaComplementar()"
                  :disabled-dates="disabledDatesInicioComplementar()"
                />
                <span class="text-danger text-sm" v-show="errors[0]">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>

            <div class="w-1/4 px-2">
              <p class="font-semibold">Prazo dias</p>
              <ValidationProvider
                name="Prazo em dias complementar"
                :rules="{
                  required: (selectedModalidadeComplementar || {}).Id > 0
                }"
                v-slot="{ errors }"
              >
                <vs-input
                  class="w-full"
                  size="large"
                  v-model="vigenciaDiasComplementar"
                  @change="calculoDataFimVigenciaComplementar()"
                />
                <span class="text-danger text-sm" v-show="errors[0]">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>

            <div class="w-1/4 px-2">
              <p class="font-semibold">Final vigência</p>
              <ValidationProvider
                name="Fim da vigência complementar"
                :rules="{
                  required: (selectedModalidadeComplementar || {}).Id > 0
                }"
                v-slot="{ errors }"
              >
                <datepicker
                  :language="ptBR"
                  format="dd/MM/yyyy"
                  class="w-full"
                  v-model="vigenciaFimComplementar"
                  @input="calculoDiasVigenciaComplementar()"
                  :disabled-dates="disabledDatesFimComplementar()"
                />
                <small v-if="showErroData" class="text-danger">{{
                  erroData
                }}</small>
                <span class="text-danger text-sm" v-show="errors[0]">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
          </div>
        </template>

        <div v-if="podeExibirCoCorretagemMapfre" class="vx-row px-2">
          <div class="w-1/2">
            <vs-checkbox class="mb-2" v-model="possuiCocorretagemMapfre">
              <p class="font-semibold">Cocorretagem</p>
            </vs-checkbox>

            <div class="px-5" v-if="possuiCocorretagemMapfre">
              <ValidationProvider
                name="codigo quadro"
                rules="number"
                v-slot="{ errors }"
              >
                <p class="">Código do quadro de distribuição (opcional)</p>
                <vs-input
                  label=""
                  size="large"
                  v-model="codigoQuadroDistribuicao"
                  class="w-1/2"
                  maxlength="10"
                  @input="validarNumeros($event, 'codigoQuadroDistribuicao')"
                />
                <span class="text-danger text-sm" v-show="errors[0]">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </div>
          </div>
        </div>

        <div v-if="podeExibirCosseguroMapfre" class="vx-row px-2">
          <div class="w-1/2">
            <p class="font-semibold mb-2">Cosseguro</p>
            <div class="px-5 w-1/2">
              <el-select
                v-model="tipoCosseguro"
                placeholder="Selecione"
                class="cosseguro-mapfre-select"
              >
                <el-option
                  v-for="cosseguro in tiposCosseguros"
                  :key="cosseguro.Id"
                  :label="cosseguro.Nome"
                  :value="cosseguro.Id"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>

        <contractual-terms
          v-if="selectedModalidadePrincipal && selectedModalidadePrincipal.Id"
          :modalidadeObjeto="modalidadeObjeto"
          :modalidadeComplementarObjeto="modalidadeComplementarObjeto"
          :coberturasAdicionaisObjeto="coberturasAdicionaisObjeto"
          :defaultTags="defaultTags"
          :tagsSeguradora="tagsSeguradora"
          @updatePropostaTermos="updatePropostaTermos"
          :cleanTags="cleanActive"
        ></contractual-terms>

        <particular-clauses-select
          v-if="selectedModalidadePrincipal"
          v-model="selectedParticularClauses"
          :proposal="proposta"
          :modalityId="selectedModalidadePrincipal.Id"
          :proposalClausesRequired="proposalClausesProposalRequiredValues || []"
          @proposal-terms-values-change="
            particularClausesProposalTermsValuesChanged
          "
        />
      </section>

      <aside class="vx-col w-4/12 pl-16">
        <div class="vx-row">
          <div class="vx-col w-full">
            <p class="font-semibold">Coberturas adicionais</p>
            <ul class="mt-8" v-if="(coberturas || []).length > 0">
              <li
                v-for="(cobertura, index) in coberturas"
                :key="cobertura.id"
                :class="{ 'pt-5': index > 0 }"
              >
                <vs-checkbox
                  @change="atualizaCoberturas()"
                  v-model="coberturasAdicionais"
                  :vs-value="cobertura.Id"
                  >{{ cobertura.Name }}</vs-checkbox
                >
              </li>
            </ul>
            <p v-if="(coberturas || []).length === 0" class="mt-3">
              NÃO PERMITE CONTRATAÇÃO DE COBERTURAS ADICONAIS PARA ESTA
              MODALIDADE
            </p>
          </div>
        </div>

        <div class="vx-row mt-12" v-if="canViewLimits">
          <div class="vx-col w-full">
            <p class="font-semibold">Prêmio Estimado</p>
            <currency-input
              :value="premio"
              class="w-full vs-inputx vs-input--input large hasValue"
              disabled
            />

            <vs-alert color="warning" class="h-auto mt-2">
              Prêmio estimado. Este valor poderá sofrer alterações durante a
              avaliação de risco.
            </vs-alert>

            <vs-alert
              class="h-auto mt-2"
              v-if="(selectedModalidadePrincipal || {}).CanUseModalityTax"
            >
              Esta modalidade possui taxa diferenciada, sendo a mesma
              <b
                ><u>{{ agravationType }}</u></b
              >
              com relação às taxas originais do Grupo de Modalidades em
              <b>{{ (selectedModalidadePrincipal || {}).ModalityTaxValue }}%</b
              >.
            </vs-alert>
          </div>
        </div>

        <template v-if="(selectedModalidadePrincipal || {}).CanHaveBeneficiary">
          <dados-beneficiario
            :allowedPF="
              (selectedModalidadePrincipal || {}).CanHaveBeneficiaryPF
            "
            :podeHabilitarCadastroNomeSocialBeneficiario="
              podeHabilitarCadastroNomeSocialBeneficiario
            "
          ></dados-beneficiario>
        </template>
      </aside>

      <div class="vx-col w-full mt-8 flex flex-row-reverse footer-postion">
        <vs-button
          color="success"
          class="ml-4"
          type="filled"
          :disabled="invalid"
          @click="salvarProposta(), (label = 'Proposta')"
          >Enviar para seguradora</vs-button
        >
        <!---
            Foi comentado pois nessa etapa a proposta ainda não existe. ela seria uma cotação e não uma proposta.
            Logo não poderia imprimir a proposa nem gera minuta.
            Isso será confirmado com o Raphael.s
          --->
        <!--<vs-button class="button-default ml-4" type="filled">Imprimir proposta</vs-button>
          <vs-button class="button-default ml-4" type="filled">Gerar Minuta</vs-button>--->
        <!-- <vs-button
          class="button-default"
          type="filled"
          :disabled="invalid"
          @click="salvarProposta(true), (label = 'Cotação')"
          >Salvar cotação</vs-button
        >
        <vs-button
          v-if="canViewLimits && !showCotation"
          class="button-default mr-4"
          type="filled"
          :disabled="invalid"
          @click="imprimirCotacao(true), (label = 'Cotação')"
          >Imprimir Cotação</vs-button
        > -->
      </div>

      <poposal-step-rules-modal
        :showModal="enviarParaSeguradoraModal"
        :stepList="getSteps"
        :showErrorButton="showTimelineErrorButton"
        :showSuccessButton="
          !showTimelineErrorButton &&
            !showRequiredDocumentsButton &&
            !!proposta.UniqueId
        "
        :showPendingButton="showRequiredDocumentsButton"
        :showLeaveButton="!(showTimelineButton && proposta.UniqueId)"
        :showButtons="showActionButtons"
        :showExeption="showExeptionButton"
        :labelModal="label"
        @onClickSuccessButton="goToStep4()"
        @onClickErrorButton="goToTimeline()"
        @onClickCloseButton="goToDashboard()"
        @onClickLeaveButton="closeModal()"
        @onClickPendingButton="sendDocuments()"
        @setActionModal="enviarParaSeguradoraModal"
        labelErrorButton="Seguir para timeline"
        labelSuccessButton="Seguir para emissão"
      />

      <vs-popup
        class="modalCancelation"
        :active.sync="showModalCancelationProposal"
        title=""
        :button-close-hidden="true">
        <div>
          <h3>O valor da proposta excede o limite aprovado.</h3>
          <br>
          <h6>Deseja revisar limite deste Tomador para continuar com a proposta?</h6>
        </div>
        <div>
          <vs-button color="primary" class="mt-8 px-20 button-default" type="filled" @click="continuarProposta()">
            Continuar com a proposta
          </vs-button>

          <vs-button style="background-color: #cb2020 !important; float: inline-end;" class="mt-8 ml-3" type="filled" @click="cancelarProposta()">
            Cancelar proposta
          </vs-button>
        </div>
      </vs-popup>

      <template v-if="((detalhamento || {}).RequestDocuments || []).length">
        <div class="documentos">
          <required-documents
            :proposal="proposta"
            :detalhamento="detalhamento"
            :propostaUniqueId="proposalUniqueId"
            :showModal="popupDetalhamento"
            :showFollowUp="true"
            @closeModal="closeModalDocuments"
            @dismissModal="closeModalDocuments"
            @updateDocs="updateDocs"
          />
        </div>
      </template>
    </div>
  </ValidationObserver>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import { ptBR } from "vuejs-datepicker/dist/locale";
import { TheMask } from "vue-the-mask";
import ParticularClausesSelect from "@/components/propostas/ParticularClausesSelect.vue";
import Datepicker from "vuejs-datepicker";
import axiosInstance from "@/axios";
import Upload from "@/components/az-upload/AzUpload.vue";
import RequiredDocuments from "../RequiredDocuments";
import TagHelpers from "@/helpers/TagHelpers";
import ContractualTerms from "@/components/contractual-terms/ContractualTerms.vue";
import PoposalStepRulesModal from "@/components/propostas/PoposalStepRulesModal.vue";
import environment from "@/environment";
import DadosBeneficiario from "./DadosBeneficiario.vue";

export default {
  name: "dados-segurado",
  components: {
    Datepicker,
    TheMask,
    Upload,
    RequiredDocuments,
    ContractualTerms,
    ParticularClausesSelect,
    PoposalStepRulesModal,
    DadosBeneficiario
  },
  props: {
    seguradoUniqueId: {
      type: String,
      required: true
    },
    seguradoId: {
      type: Number,
      required: true
    },
    idTomador: {
      type: Number,
      required: true
    },
    tomadorUniqueId: {
      type: String,
      required: true
    },
    edicaoPropostaEndosso: {
      type: Boolean,
      required: false
    },
    podeHabilitarCadastroNomeSocialBeneficiario: {
      type: Boolean,
      required: false
    },
    tipoCanalCorretora: {
      type: Object,
      default: function() {
        return {
          Id: "",
          Nome: "",
          Descricao: ""
        };
      }
    },
    permissoesCocorretagemMapfre: {
      type: Array,
      default: function() {
        return [];
      }
    },
    permissoesCosseguroMapfre: {
      type: Array,
      default: function() {
        return [];
      }
    }
  },
  data() {
    return {
      isDisabledEnd: true,
      isDisabledEndComplementary: true,
      isDisabledComplementary: true,
      showExeptionButton: false,
      condicoesMercado: false,
      cleanActive: false,
      proposalUniqueId: null,
      popupDetalhamento: false,
      detalhamento: {
        RequiredDocuments: []
      },
      label: "",
      percLimit: 0,
      enviarParaSeguradoraModal: false,
      enviarParaSeguradoraSteps: [],
      ptBR,
      selectedGrupo: undefined,
      selectedModalidadePrincipal: undefined,
      selectedModalidadeComplementar: undefined,
      selectedParticularClauses: null,
      value1: 0,
      numeroProcesso: undefined,
      obrigacoesFiscais: undefined,
      obrigacoesTrabalhistas: undefined,
      multas: undefined,
      modalidades: [],
      modalidadePrincipal: [],
      modalidadeComplementar: [],
      beneficiaries: [],
      text: {
        textModalidades: "São os grupos de produtos do Seguro Garantia",
        textModalidadePrincipal:
          "São os produtos do Seguro Garantia que atendem as diversas necessidades!",
        textImportanciaSegurada:
          "Significa o mesmo que capital segurado e trata-se do valor estabelecido na apólice de seguro para a garantia contratada, determinando o valor máximo de indenização a ser paga pela seguradora em caso de sinistro."
      },
      showErroData: false,
      erroData: "",
      coberturas: [],
      particularClauses: [],
      particularClausesInputs: [],
      particularClausesHtml: "",
      particularClausesHtmlFormated: "",
      coberturasAdicionais: [],
      modalidadeObjeto: undefined,
      coberturasAdicionaisObjeto: undefined,
      modalidadeComplementarObjeto: undefined,
      showTimelineErrorButton: false,
      showRequiredDocumentsButton: false,
      showModalRequiredDocuments: false,
      documents: [],
      showActionButtons: false,
      showLeaveButton: false,
      showTimelineButton: false,
      objeto: {},
      camposObjeto: [],
      camposObjeto: [],
      tagsSeguradora: [],
      propostaTermos: [],
      requiredDocuments: [],
      repeaterArray: [],
      particularClausesProposalTermsValues: null,
      proposalClausesProposalTermsValues: null,
      proposalClausesProposalRequiredValues: null,
      applicationConfig: {},
      showModalCancelationProposal: false,
      possuiCocorretagemMapfre: false,
      codigoQuadroDistribuicao: "",
      possuiCosseguroMapfre: false,
      tipoCosseguro: 0,
      tiposCosseguros: [
        {
          Nome: "0 - Isento",
          Id: 0
        },
        {
          Nome: "1 - Cedido",
          Id: 1
        },
        {
          Nome: "2 - Aceito",
          Id: 2
        }
      ],
      isMapfre: false
    };
  },
  async mounted() {
    this.isMapfre =
      environment.CompanyId.Mapfre ==
      this.$store.state["auth"].userInfo.InsuranceId;

    await this.init();
  },
  watch: {
    proposta(val) {},
    "proposta.InsuredId"(newVal, oldVal) {
      //Foi adicionado isso, pois se a pessoa voltar e alterar o segurado
      //Devemos recarregar os dados dessa tela;
      if (newVal != undefined && newVal != oldVal) {
        this.grupoModalidadeSelecionado(undefined);
        this.init();
      }
    },
    "proposta.PolicyHolderId"(newVal, oldVal) {
      //Foi adicionado isso, pois se a pessoa voltar e alterar o segurado
      //Devemos recarregar os dados dessa tela;
      if (newVal != undefined && newVal != oldVal) {
        this.grupoModalidadeSelecionado(undefined);
        this.init();
      }
    },
    edicaoDeProposta: {
      immediate: true,
      handler(val, oldVal) {
        if (val) {
          setTimeout(this.loadPropostaInfo, 400);
        }
      }
    }
  },
  computed: {
    ...mapGetters("proposta-module", ["proposta", "edicaoDeProposta"]),
    ...mapGetters("auth", ["userInfo", "advisorInfo"]),
    agravationType: {
      get() {
        return (this.selectedModalidadePrincipal || {}).CalculationType ==
          "Agravation"
          ? "agravada"
          : "reduzida";
      }
    },
    canViewLimits: {
      get() {
        return ((this.proposta || {}).PolicyHolder || {}).CanViewLimitsAndRates;
      }
    },
    getSteps: {
      get() {
        return this.enviarParaSeguradoraSteps || [];
      }
    },

    importanciaSegurada: {
      get() {
        return this.proposta.InsuredAmountValue;
      },
      set(val) {
        this.$store.commit("proposta-module/updateProposta", {
          ...this.proposta,
          InsuredAmountValue: val
        });
      }
    },

    importanciaSeguradaComplementar: {
      get() {
        return this.proposta.ComplementaryInsuredAmountValue;
      },
      set(val) {
        this.$store.commit("proposta-module/updateProposta", {
          ...this.proposta,
          ComplementaryInsuredAmountValue: val
        });
      }
    },

    vigenciaInicioPrincipal: {
      get() {
        return this.proposta.StartDate;
      },
      set(val) {
        this.$store.commit("proposta-module/updateProposta", {
          ...this.proposta,
          StartDate: val
        });
      }
    },

    vigenciaFimPrincipal: {
      get() {
        return this.proposta.EndDate;
      },
      set(val) {
        this.$store.commit("proposta-module/updateProposta", {
          ...this.proposta,
          EndDate: val
        });
      }
    },

    vigenciaDiasPrincipal: {
      get() {
        return this.proposta.DeadlineDays;
      },
      set(val) {
        this.$store.commit("proposta-module/updateProposta", {
          ...this.proposta,
          DeadlineDays: val
        });
      }
    },

    vigenciaInicioComplementar: {
      get() {
        return this.proposta.ComplementaryStartDate;
      },
      set(val) {
        this.$store.commit("proposta-module/updateProposta", {
          ...this.proposta,
          ComplementaryStartDate: val
        });
      }
    },

    vigenciaFimComplementar: {
      get() {
        return this.proposta.ComplementaryEndDate;
      },
      set(val) {
        this.$store.commit("proposta-module/updateProposta", {
          ...this.proposta,
          ComplementaryEndDate: val
        });
      }
    },

    vigenciaDiasComplementar: {
      get() {
        return this.proposta.ComplementaryDeadlineDays;
      },
      set(val) {
        this.$store.commit("proposta-module/updateProposta", {
          ...this.proposta,
          ComplementaryDeadlineDays: val
        });
      }
    },

    premio: {
      get() {
        return this.proposta.InsurancePremium;
      },
      set(val) {
        this.$store.commit("proposta-module/updateProposta", {
          ...this.proposta,
          InsurancePremium: val
        });
      }
    },

    defaultTags() {
      return TagHelpers.defaultTags(
        this.proposta,
        this.tagsSeguradora.map(x => {
          return { [x.Name]: x.Value };
        })
      );
    },

    podeExibirCoCorretagemMapfre() {
      return this.permissoesCocorretagemMapfre.includes("ExibirCoCorretagem");
    },

    podeExibirCosseguroMapfre() {
      return this.permissoesCosseguroMapfre.includes("ExibirCosseguro");
    }
  },
  methods: {
    ...mapMutations("proposta-module", [
      "updateProposta",
      "updateFantasyNameProposal",
      "EmissionProposalType"
    ]),
    ...mapActions("propostas-module", [
      "propostaCredito",
      "propostaAnalisePEP",
      "propostaAnaliseCadastro",
      "propostaSubscricao",
      "propostaResseguro"
    ]),
    ...mapActions("proposta-module", [
      "validateRequiredProposalDocuments",
      "getCompanyProposalTags"
    ]),
    cleanTag() {
      this.cleanActive = !this.cleanActive;
    },
    closeModalDocuments() {
      this.goToTimeline();
      this.popupDetalhamento = false;
    },

    updateDocs(docs) {
      this.detalhamento.RequestDocuments = docs;
    },

    validShowBottonsModalRules(documents, hasError, propostaUniqueId, row) {
      this.showActionButtons = true;

      if (hasError) {
        if (documents.length > 0) {
          this.showRequiredDocumentsButton = true;
          this.proposalUniqueId = propostaUniqueId;
          this.detalhamento.RequestDocuments = documents;
        } else {
          this.showTimelineErrorButton = true;
        }
        if (
          row.Errors[row.Errors.length - 1] ===
          "Ocorreu um erro inesperado que impossibilitou o cadastro da proposta, gentileza tentar novamente ou entrar em contato com a seguradora."
        ) {
          this.showTimelineErrorButton = false;
          this.showExeptionButton = true;
        }
      }
    },

    sendDocuments() {
      this.enviarParaSeguradoraModal = false;
      this.popupDetalhamento = true;
    },

    particularClausesProposalTermsValuesChanged(proposalTermsValues) {
      this.particularClausesProposalTermsValues = proposalTermsValues;
    },

    sendParticularClauses() {
      if (
        !this.particularClausesProposalTermsValues ||
        this.particularClausesProposalTermsValues.length == 0
      )
        return;

      this.propostaTermos = (
        this.proposta.ProposalContractualTerms || []
      ).concat(this.particularClausesProposalTermsValues);

      this.$store.commit("proposta-module/updateProposta", {
        ...this.proposta,
        ProposalContractualTerms: this.propostaTermos
      });
    },

    atualizaObjeto() {
      this.$store.commit("proposta-module/updateProposta", {
        ...this.proposta,
        ObjectJSON: this.objeto ? JSON.stringify(this.objeto) : "{}"
      });
    },

    async atualizaCoberturas() {
      let mapCoberturas = [];
      if (this.coberturasAdicionais && this.coberturasAdicionais.length > 0)
        mapCoberturas = this.coberturasAdicionais.map(item => {
          return { CoverageId: item };
        });

      this.$store.commit("proposta-module/updateProposta", {
        ...this.proposta,
        ProposalCoverages: mapCoberturas
      });
      this.calculoValorPremio(3);
      await this.getCoberturasAdicionaisObjeto();
    },
    goToDashboard() {
      this.$appInsights.trackEvent({ name: "sair-proposta", properties: { place: "proposta-passo-3", action: "button-click", slug: "sair-proposta-passo-3" }});
      this.enviarParaSeguradoraModal = false;
      setTimeout(() => {
        this.$router.push("/propostas");
      }, 500);
    },

    goToStep4() {
      if (this.$route.name == "editar-proposta") {
        location.reload();
      } else {
        this.$appInsights.trackEvent({ name: "go-to-emissao-proposta", properties: { place: "proposta-passo-3", action: "button-click", slug: "go-to-emissao-proposta-passo-3" }});
        this.$router.push({
          name: "editar-proposta",
          params: { propostaUniqueId: this.proposta.UniqueId }
        });
      }
    },

    goToTimeline() {
      this.$appInsights.trackEvent({ name: "go-to-timeline-proposta", properties: { place: "proposta-passo-3", action: "button-click", slug: "go-to-timeline-proposta-passo-3" }});
      return this.$router.push({
        name: "timeline-proposta",
        params: { propostaUniqueId: this.proposta.UniqueId }
      });
    },

    calculoValorPremio2() {
      let premioModalidadePrincipal = 0;
      let premioModalidadePrincipalAntesDasCoberturas = 0;
      let premioModalidadeComplementar = 0;
      let premioTotal = 0;
      if (
        this.importanciaSegurada !== undefined &&
        (this.selectedGrupo || {}).RiskRate !== undefined &&
        this.vigenciaDiasPrincipal !== undefined
      ) {
        var riskRate = (this.selectedGrupo || {}).RiskRate || 0;

        if ((this.selectedModalidadePrincipal || {}).CanUseModalityTax) {
          if (
            (this.selectedModalidadePrincipal || {}).CalculationType ==
            "Agravation"
          ) {
            riskRate =
              riskRate +
              riskRate *
                ((this.selectedModalidadePrincipal || {}).ModalityTaxValue /
                  100);
          } else if (
            (this.selectedModalidadePrincipal || {}).CalculationType == "Reduce"
          ) {
            riskRate =
              riskRate -
              riskRate *
                ((this.selectedModalidadePrincipal || {}).ModalityTaxValue /
                  100);
          }
        }

        premioModalidadePrincipal =
          (this.importanciaSegurada || 0) *
          (riskRate / 100) *
          ((this.vigenciaDiasPrincipal || 0) / 365);

        if (this.condicoesMercado) {
          if (this.condicoesMercado.FixedInsurancePremium) {
            premioModalidadePrincipal = this.condicoesMercado
              .FixedInsurancePremium;
          } else {
            if (
              premioModalidadePrincipal <
              (this.condicoesMercado || {}).MinInsurancePremium
            ) {
              premioModalidadePrincipal = this.condicoesMercado
                .MinInsurancePremium;
            }
          }
        } else {
          if (
            premioModalidadePrincipal <
            (this.selectedModalidadePrincipal || {}).MinInsurancePremium
          ) {
            premioModalidadePrincipal = (this.selectedModalidadePrincipal || {})
              .MinInsurancePremium;
          }
        }

        //Se tiver modalidade complementar
        if ((this.importanciaSeguradaComplementar || 0) > 0) {
          premioModalidadeComplementar =
            (this.importanciaSeguradaComplementar || 0) *
            ((this.selectedGrupo.RiskRate || 0) / 100) *
            ((this.vigenciaDiasComplementar || 0) / 365);
          //this.proposta.InsurancePremiumComplementaryModality = premioComplementar;
          if (
            premioModalidadeComplementar <
            (this.selectedModalidadeComplementar || {}).MinInsurancePremium
          )
            premioModalidadeComplementar = (
              this.selectedModalidadeComplementar || {}
            ).MinInsurancePremium;
        }

        premioTotal = premioModalidadePrincipal + premioModalidadeComplementar;
        premioModalidadePrincipalAntesDasCoberturas = premioModalidadePrincipal;
        /**else {
          this.premio = premio
        }**/
        if (this.coberturasAdicionais.length > 0) {
          //const valorPremio = this.proposta.InsurancePremiumPrincipalModality
          this.coberturasAdicionais.forEach(c => {
            const cobertura = this.coberturas.find(cob => cob.Id == c);

            if (cobertura.CoverageCalculationTypeId == 1) {
              //Por agravo de taxa;
              const valorAgravo =
                (((cobertura.CalculationParameter || {}).AggravationRate || 0) /
                  100) *
                premioModalidadePrincipalAntesDasCoberturas;
              if (valorAgravo > 0) {
                premioModalidadePrincipal =
                  premioModalidadePrincipal + valorAgravo;
                premioTotal += valorAgravo;
              }
            } else if (cobertura.CoverageCalculationTypeId == 2) {
              // Por distribuição do prêmio
              const valorDistribuicao =
                (((cobertura.CalculationParameter || {}).DistributionRate ||
                  0) /
                  100) *
                premioModalidadePrincipalAntesDasCoberturas;
              if (valorDistribuicao > 0)
                premioModalidadePrincipal =
                  premioModalidadePrincipal - valorDistribuicao;
            }
          });
        }

        //Premio fixo
        if (this.condicoesMercado) {
          if (this.condicoesMercado.FixedInsurancePremium) {
            this.premio = this.condicoesMercado.FixedInsurancePremium;
          } else {
            this.premio = premioTotal;
          }
        } else {
          this.premio = premioTotal;
        }

        this.updateProposta({
          ...this.proposta,
          InsurancePremiumPrincipalModality: premioModalidadePrincipal,
          InsurancePremiumComplementaryModality: premioModalidadeComplementar
        });
      }
    },
    calculoValorPremio(type) {
      let dados = this.proposta;
      console.log("aqui: ", this.selectedModalidadeComplementar);

      if (this.selectedModalidadeComplementar) {
        if (
          dados.ComplementaryInsuredAmountValue &&
          dados.ComplementaryStartDate &&
          dados.ComplementaryDeadlineDays
        ) {
          console.log("passou 2");
        } else {
          console.log("nao passou 2");
          return;
        }
      }

      dados.BrokerId = this.$store.state["auth"].userInfo.BrokerId
        ? this.$store.state["auth"].userInfo.BrokerId
        : 0;
      console.log(
        this.importanciaSegurada,
        dados.EndDate,
        dados.StartDate,
        dados.DeadlineDays
      );
      if (dados.StartDate && dados.DeadlineDays) {
        console.log("passou");
      } else {
        return;
      }
      let datePayload = {
        PrincipalModalityDate: {
          EndDate: type == 7 ? dados.EndDate : null,
          DeadlineDays: type == 7 ? null : dados.DeadlineDays,
          StartDate: dados.StartDate
        }
      };
      console.log("datePayload", datePayload);
      this.$vs.loading();
      return axiosInstance
        .post(`api/Proposal/GetCalculationProposalDate`, datePayload)
        .then(response => {
          this.isDisabledEnd = false;
          this.isDisabledComplementary = false;
          console.log("response.data.Response", response.data.Response);

          this.$onpoint.loading(() => {
            return axiosInstance({
              method: "post",
              url: "api/RestoreInsurancePremium/GetProposalInsurancePremium",
              data: dados
            })
              .then(response => {
                this.$vs.loading.close();
                console.log(
                  response.data.Response.InsurancePremiumPrincipalModality,
                  response.data.Response.InsurancePremiumComplementarModality
                );
                this.premio = response.data.Response.InsurancePremium;
                this.updateProposta({
                  ...this.proposta,
                  InsurancePremiumPrincipalModality:
                    response.data.Response.InsurancePremiumPrincipalModality,
                  InsurancePremiumComplementaryModality:
                    response.data.Response.InsurancePremiumComplementarModality
                });
              })
              .catch(errors => {
                this.$onpoint.errorModal(errors.response.data.Errors);
                this.$vs.loading.close();
              });
          });
        })
        .catch(data => {
          this.$vs.loading.close();
          return Promise.reject(data);
        });
    },
    async init() {
      await this.loadGetCompanyProposalTags();

      this.applicationConfig = environment.getApplicationConfig();
      if (!this.$route.params.propostaUniqueId) {
        await this.getGrupoModalidades();
      }
    },

    setProposalClauses(required) {
      return (
        ((this.proposta || {}).ProposalContractualTerms || []).filter(
          item =>
            item.TypeId == 6 &&
            item.ParticularClause.IsFixedClause == !!required
        ) || []
      ).map(term => {
        let clause = {
          ...term.ParticularClause,
          ContractualTermId: term.ContractualTermId,
          ContractualTerm: {
            ...term.ContractualTerm,
            JSONTagValue: term.JSONTagValue,
            ContractualTermId: term.ContractualTermId
          }
        };

        return clause;
      });
    },

    async loadPropostaInfo() {
      this.selectedParticularClauses = this.setProposalClauses(false);
      this.proposalClausesProposalRequiredValues = this.setProposalClauses(
        true
      );

      if ((this.proposta.ProposalCoverages || []).length > 0) {
        this.coberturasAdicionais = this.proposta.ProposalCoverages.map(
          item => item.CoverageId
        );
      }

      await this.getGrupoModalidades();
      this.selectedGrupo = this.proposta.ModalityGroup;
      this.selectedGrupo.RiskRate = this.proposta.RiskRate;

      if (this.selectedGrupo.UniqueId) {
        await this.getModalidadePorGrupo(this.selectedGrupo.UniqueId);
        const selectedModalidade = this.modalidadePrincipal.find(
          mod => mod.Id === this.proposta.ModalityId
        );
        this.selectedModalidadePrincipal = selectedModalidade;
        await this.getModalidadeObjeto(this.selectedModalidadePrincipal.Id);
        await this.getCoberturas();
        await this.getPercentageLimitPremiumRelationInsuredAmount();
        if (this.selectedModalidadePrincipal.HasComplementaryModality) {
          await this.getModalidadeComplementar(
            this.selectedModalidadePrincipal.UniqueId
          );
          const selectedModalidadeComplementar = this.modalidadeComplementar.find(
            mod => mod.Id === this.proposta.ComplementaryModalityId
          );
          this.selectedModalidadeComplementar = selectedModalidadeComplementar;
          await this.getModalidadeObjetoComplementar(
            this.selectedModalidadeComplementar
          );
        }

        if ((this.coberturasAdicionais || []).length > 0)
          await this.getCoberturasAdicionaisObjeto();
      }
    },

    updateStep(data) {
      if (!this.enviarParaSeguradoraSteps) this.enviarParaSeguradoraSteps = [];

      const idx = this.enviarParaSeguradoraSteps.findIndex(item => {
        return item.name === data.name;
      });
      if (idx > -1) {
        this.enviarParaSeguradoraSteps.splice(idx, 1);
      }

      this.enviarParaSeguradoraSteps.push(data);

      if (data.status === "wait") {
        setTimeout(() => {
          this.$vs.loading({
            container: `#loading-step-${data.name}`,
            scale: 0.6
          });
        }, 300);
      }
    },

    closeModal() {
      this.$appInsights.trackEvent({ name: "fechar-modal-enviar-seguradora-proposta", properties: { place: "proposta-passo-3", action: "button-click", slug: "fechar-modal-enviar-seguradora-proposta-passo-3" }});
      this.enviarParaSeguradoraModal = false;
    },

    async enviarPropostaAnaliseCadastro(propostaUniqueId) {
      this.showActionButtons = false;
      this.showTimelineErrorButton = false;
      this.showRequiredDocumentsButton = false;

      const step = {
        name: "aprovacao_cadastro",
        status: "wait",
        message: "Executando regras de cadastro."
      };

      this.enviarParaSeguradoraModal = true;
      this.updateStep(step);

      return await this.propostaAnaliseCadastro(propostaUniqueId)
        .then((response) => {
          if (!response || !response.Success) {
            this.updateStep({
              ...step,
              status: "error",
              message: "Regras de aprovação de cadastro.",
              error: response.Errors
            });

            this.validShowBottonsModalRules(
              response.RequiredDocuments,
              true,
              propostaUniqueId,
              response
            );
            return;
          }

          if (response.Success && (response.Alerts || []).length > 0) {
            this.updateStep({
              ...step,
              status: "success",
              message: "Regras de aprovação de cadastro executada com sucesso.",
              alerts: response.Alerts || []
            });
          } else {
            this.updateStep({
              ...step,
              status: "success",
              message: "Regras de aprovação de cadastro executada com sucesso."
            });
          }

          this.enviarPropostaPEP(propostaUniqueId);
        })
        .catch(error => {
          this.validShowBottonsModalRules([], true, propostaUniqueId, response);
          this.updateStep({
            ...step,
            status: "error",
            message: "Regras de aprovação de cadastro.",
            error: error.response.data.Errors
          });
        });
    },

    async enviarPropostaPEP(propostaUniqueId) {
      const step = {
        name: "aprovacao_pep",
        status: "wait",
        message: "Executando regras de aprovação automática de PEP."
      };
      this.enviarParaSeguradoraModal = true;
      this.updateStep(step);

      return await this.propostaAnalisePEP(propostaUniqueId)
        .then(async (response) => {
          if (!response || !response.Success) {
            this.updateStep({
              ...step,
              status: "error",
              message: "Regras de aprovação automática de PEP.",
              error: response.Errors
            });

            this.validShowBottonsModalRules(
              response.RequiredDocuments,
              true,
              propostaUniqueId,
              response
            );
            return;
          }
          if (response.Success && (response.Alerts || []).length > 0) {
            this.updateStep({
              ...step,
              status: "success",
              message:
                "Regras de aprovação automática de PEP executada com sucesso.",
              alerts: response.Alerts || []
            });
          } else {
            this.updateStep({
              ...step,
              status: "success",
              message:
                "Regras de aprovação automática de PEP executada com sucesso."
            });
          }
          await this.enviarPropostaCredito(propostaUniqueId);
        })
        .catch(error => {
          this.validShowBottonsModalRules([], true, propostaUniqueId, response);

          this.updateStep({
            ...step,
            status: "error",
            message: "Regras de aprovação automática de PEP.",
            error: error.response.data.Errors
          });
        });
    },

    continuarProposta()
    {
      this.showModalCancelationProposal = false;
      this.popupDetalhamento = true;
    },

    cancelarProposta() {
      this.enviarParaSeguradoraModal = false;
      this.showModalCancelationProposal = false;

      this.$onpoint.loadingModal("Estamos cancelando a proposta", () => {
        return axiosInstance
          .post(
            `api/Proposal/CancelProposal?ProposalUniqueId=${this.proposta.UniqueId}`
          )
          .then(() => {
            this.$router.push({ name: "propostas" });
          })
          .catch(errors =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    },

    async enviarPropostaCredito(propostaUniqueId) {
      const step = {
        name: "aprovacao_credito",
        status: "wait",
        message: "Executando regras de aprovação automática de crédito."
      };
      this.enviarParaSeguradoraModal = true;
      this.updateStep(step);
      return await this.propostaCredito(propostaUniqueId)
        .then(async (response) => {
          if (!response || !response.Success) {
            this.updateStep({
              ...step,
              status: "error",
              message: "Regras de aprovação automática de crédito.",
              error: response.Errors
            });

            this.validShowBottonsModalRules(
              response.RequiredDocuments,
              true,
              propostaUniqueId,
              response
            );

            if (response.ExibirOpcaoUsuario){
              this.enviarParaSeguradoraModal = false;
              this.showModalCancelationProposal = true;
            }

            return;
          }
          if (response.Success && (response.Alerts || []).length > 0) {
            this.updateStep({
              ...step,
              status: "success",
              message:
                "Regras de aprovação automática de crédito executada com sucesso.",
              alerts: response.Alerts || []
            });
          } else {
            this.updateStep({
              ...step,
              status: "success",
              message:
                "Regras de aprovação automática de crédito executada com sucesso."
            });
          }

          await this.enviarPropostaSubscricao(propostaUniqueId);
        })
        .catch(error => {
          this.validShowBottonsModalRules([], true, propostaUniqueId, response);

          this.updateStep({
            ...step,
            status: "error",
            message: "Regras de aprovação automática de crédito.",
            error: error.response.data.Errors
          });
        });
    },

    async enviarPropostaSubscricao(propostaUniqueId) {
      const step = {
        name: "aprovacao_subscricao",
        status: "wait",
        message: "Executando regras de aprovação automática de subscrição."
      };
      this.updateStep(step);

      return await this.propostaSubscricao(propostaUniqueId)
        .then(async (response) => {
          if (!response || !response.Success) {
            this.updateStep({
              ...step,
              status: "error",
              message:
                "Regras de aprovação automática de subscrição executada.",
              error: response.Errors
            });

            this.validShowBottonsModalRules(
              response.RequiredDocuments,
              true,
              propostaUniqueId,
              response
            );
            return;
          }

          if (response.Success && (response.Alerts || []).length > 0) {
            this.updateStep({
              ...step,
              status: "success",
              message:
                "Regras de aprovação automática de subscrição executada com sucesso.",
              alerts: response.Alerts || []
            });
          } else {
            this.updateStep({
              ...step,
              status: "success",
              message:
                "Regras de aprovação automática de subscrição executada com sucesso."
            });
          }

          await this.enviarPropostaResseguro(propostaUniqueId);
        })
        .catch(error => {
          this.validShowBottonsModalRules([], true, propostaUniqueId, response);

          this.updateStep({
            ...step,
            status: "error",
            message: "Regras de aprovação automática de subscrição executada.",
            error: error.response.data.Errors
          });
        });
    },

    async enviarPropostaResseguro(propostaUniqueId) {
      const step = {
        name: "aprovacao_resseguro",
        status: "wait",
        message: "Executando regras de aprovação automática de resseguro."
      };
      this.updateStep(step);
      return await this.propostaResseguro(propostaUniqueId)
        .then((response) => {
          if (!response || !response.Success) {
            this.updateStep({
              ...step,
              status: "error",
              message:
                "Regras de aprovação automática de resseguro executada com sucesso.",
              error: response.Errors
            });

            this.validShowBottonsModalRules(
              response.RequiredDocuments,
              true,
              propostaUniqueId,
              response
            );
            return;
          }

          if (response.Success && (response.Alerts || []).length > 0) {
            this.updateStep({
              ...step,
              status: "success",
              message:
                "Regras de aprovação automática de resseguro executada com sucesso.",
              alerts: response.Alerts || []
            });
          } else {
            this.updateStep({
              ...step,
              status: "success",
              message:
                "Regras de aprovação automática de resseguro executada com sucesso."
            });
          }
          this.showActionButtons = true;
        })
        .catch(error => {
          this.validShowBottonsModalRules([], true, propostaUniqueId, response);

          this.updateStep({
            ...step,
            status: "error",
            message:
              "Regras de aprovação automática de resseguro executada com sucesso.",
            error: error.response.data.Errors
          });
        });
    },

    clickEnviarParaSeguradora() {
      let error = false;
      if ((this.camposObjeto || []).length > 0) {
        this.camposObjeto.forEach(tag => {
          if (!this.objeto[tag.Name]) {
            this.$onpoint.errorModal(`Preencha o campo ${tag.Label}.`);
            error = true;
            return false;
          }
        });
      }
      if (error) return;

      return this.salvarProposta();
    },

    updatePropostaTermos(value) {
      if (!value.initValue)
        this.updateProposta({
          ...this.proposta,
          ProposalContractualTerms: value.termos,
          ObjectJSON: value.object
        });
    },

    async salvarProposta(somenteSalvaProposta) {
      this.$appInsights.trackEvent({ name: "salvar-proposta", properties: { place: "proposta-passo-3", action: "button-click", slug: "salvar-proposta-passo-3" }});
      if (this.proposta.ProposalContractualTerms.length <= 0) {
        let objeto = [
          {
            ContractualTermId: this.modalidadeObjeto.Id,
            JSONTagValue: this.modalidadeObjeto.JSONTag,
            Value: this.modalidadeObjeto.Value
          }
        ];

        this.EmissionProposalType(0);
        this.sendParticularClauses();
        this.enviarParaSeguradoraSteps = [];
        const step = {
          name: "salvar_cotacao",
          status: "wait",
          message: !somenteSalvaProposta
            ? "Salvando Proposta"
            : "Salvando Cotação."
        };
        this.enviarParaSeguradoraModal = true;
        this.updateStep(step);

        if ((this.advisorInfo || {}).PersonId > 0) {
          this.proposta.AdvisorId = this.advisorInfo.PersonId;
        }

        let payload = {
          ...this.proposta,
          ProposalContractualTerms: objeto,
          Beneficiaries: (this.proposta.Beneficiaries || []).map(x => {
            return {
              NomeSocial: x.NomeSocial,
              Id: x.Id,
              LocationId: x.LocationId || ((x.Locations || [])[0] || {}).Id,
              PercentageAmountValue: x.PercentageAmountValue
            };
          })
        };

        if (this.isMapfre) {
          payload = {
            ...payload,
            TipoCanalId: this.tipoCanalCorretora.Id,
            HasCoBrokers: this.possuiCocorretagemMapfre,
            CodigoQuadroDistribuicao: this.codigoQuadroDistribuicao,
            CoInsurancers: this.tipoCosseguro === 0 ? null : [ { CoInsuranceType:this.tipoCosseguro } ],
          };
        }

        return await axiosInstance({
          method: "post",
          url: "api/Proposal/SaveProposal",
          data: payload
        })
          .then(async(response) => {
            this.updateProposta({
              ...this.proposta,
              Id: response.data.Response.Id,
              UniqueId: response.data.Response.UniqueId,
              Number: response.data.Response.Number
            });

            this.updateStep({
              ...step,
              status: "success",
              message: !somenteSalvaProposta
                ? "Proposta salva com sucesso!"
                : `Cotação n° ${this.proposta.Number} salva com sucesso.`
            });
            if (!somenteSalvaProposta)
              return await this.enviarPropostaAnaliseCadastro(this.proposta.UniqueId);
            // this.enviarParaSeguradoraModal = false
            /**setTimeout(() => {
            this.$router.push('/propostas')
          }, 300)**/
          })
          .catch(error => {
            this.showActionButtons = true;
            this.showTimelineButton = true;
            this.updateStep({
              ...step,
              status: "error",
              message:
                "Não foi possível salvar a cotação. Corrija os pontos abaixo e tente novamente.",
              error: error.response.data.Errors
            });
          });
      } else {
        this.EmissionProposalType(0);
        this.sendParticularClauses();
        this.enviarParaSeguradoraSteps = [];
        const step = {
          name: "salvar_cotacao",
          status: "wait",
          message: !somenteSalvaProposta
            ? "Salvando Proposta"
            : "Salvando Cotação."
        };
        this.enviarParaSeguradoraModal = true;
        this.updateStep(step);

        if ((this.advisorInfo || {}).PersonId > 0) {
          this.proposta.AdvisorId = this.advisorInfo.PersonId;
        }
        
        let payload = {
          ...this.proposta,
          Beneficiaries: (this.proposta.Beneficiaries || []).map(x => {
            return {
              NomeSocial: x.NomeSocial,
              Id: x.Id,
              LocationId: x.LocationId || ((x.Locations || [])[0] || {}).Id,
              PercentageAmountValue: x.PercentageAmountValue
            };
          })
        };

        if (this.isMapfre) {
          payload = {
            ...payload,
            TipoCanalId: this.tipoCanalCorretora.Id,
            HasCoBrokers: this.possuiCocorretagemMapfre,
            CodigoQuadroDistribuicao: this.codigoQuadroDistribuicao,
            CoInsurancers: this.tipoCosseguro === 0 ? null : [ { CoInsuranceType:this.tipoCosseguro } ],
          };
        }

        return await axiosInstance({
          method: "post",
          url: "api/Proposal/SaveProposal",
          data: payload
        })
          .then(async (response) => {
            this.updateProposta({
              ...this.proposta,
              Id: response.data.Response.Id,
              UniqueId: response.data.Response.UniqueId,
              Number: response.data.Response.Number
            });

            this.updateStep({
              ...step,
              status: "success",
              message: !somenteSalvaProposta
                ? "Proposta salva com sucesso!"
                : `Cotação n° ${this.proposta.Number} salva com sucesso.`
            });
            if (!somenteSalvaProposta)
              return await this.enviarPropostaAnaliseCadastro(this.proposta.UniqueId);
            // this.enviarParaSeguradoraModal = false
            /**setTimeout(() => {
            this.$router.push('/propostas')
          }, 300)**/
          })
          .catch(error => {
            this.showActionButtons = true;
            this.showTimelineButton = true;
            this.updateStep({
              ...step,
              status: "error",
              message:
                "Não foi possível salvar a cotação. Corrija os pontos abaixo e tente novamente.",
              error: error.response.data.Errors
            });
          });
      }
    },

    async imprimirCotacao() {
      if ((this.advisorInfo || {}).PersonId > 0) {
        this.proposta.AdvisorId = this.advisorInfo.PersonId;
      }

      this.$onpoint.loading(() => {
        return axiosInstance({
          method: "post",
          url: "api/Proposal/SaveProposal",
          data: this.proposta
        })
          .then(response => {
            this.updateProposta({
              ...this.proposta,
              Id: response.data.Response.Id,
              UniqueId: response.data.Response.UniqueId,
              Number: response.data.Response.Number
            });
            return axiosInstance
              .get(
                `api/Proposal/GenerateContractDraft?ProposalUniqueId=${this.proposta.UniqueId}&IsSaved=false`
              )
              .then(response => {
                window.open(response.data.Response.UrlToDownload, "_self");
              });
          })
          .catch(errors =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    },
    modalidadeSelecionada(selectedModalidadePrincipal) {
      this.cleanTag();
      this.isDisabledComplementary = true;
      this.importanciaSeguradaComplementar = undefined;
      this.vigenciaDiasComplementar = undefined;
      this.vigenciaDiasPrincipal = undefined;
      this.importanciaSegurada = undefined;
      this.vigenciaFimPrincipal = undefined;
      this.vigenciaInicioPrincipal = undefined;
      this.modalidadeComplementar = undefined;
      this.selectedModalidadeComplementar = undefined;
      this.coberturas = [];
      this.textoObjetoHtml = undefined;
      this.modalidadeObjeto = undefined;
      this.coberturasAdicionaisObjeto = undefined;
      this.vigenciaInicioComplementa = undefined;
      this.vigenciaFimComplementar = undefined;
      this.coberturasAdicionais = [];
      this.modalidadeObjeto = {};
      this.modalidadeComplementarObjeto = {};
      this.particularClauses = [];
      this.premio = undefined;

      this.selectedParticularClauses = [];

      this.updateProposta({
        ...this.proposta,
        ModalityId: (selectedModalidadePrincipal || {}).Id,
        ComplementaryModalityId: undefined,
        ComplementaryModality: undefined,
        Modality: selectedModalidadePrincipal,
        Beneficiaries: []
        // ProposalContractualTerms: undefined,
        // ObjectJSON: undefined
      });
      if (!selectedModalidadePrincipal) return;

      const promises = [];
      // promises.push(this.getParticularClauses(selectedModalidadePrincipal.Id));
      promises.push(this.getCoberturas());
      this.getCondicoesMercado();
      promises.push(this.getPercentageLimitPremiumRelationInsuredAmount());
      if (selectedModalidadePrincipal.HasComplementaryModality) {
        promises.push(
          this.getModalidadeComplementar(selectedModalidadePrincipal.UniqueId)
        );
      }
      promises.push(this.getModalidadeObjeto(selectedModalidadePrincipal.Id));

      this.$onpoint.loading(() => {
        return Promise.all(promises);
      });
      //
    },

    disabledDatesInicio() {
      //REGRA DESABILITADA
      /****/
      let _to = undefined;
      let _from = undefined;
      if (
        this.selectedModalidadePrincipal &&
        this.selectedModalidadePrincipal.MaxRetroactivityDays > 0
      ) {
        _to = new Date(
          this.$moment().add(
            this.selectedModalidadePrincipal.MaxRetroactivityDays * -1,
            "days"
          )
        );
      } else {
        _to = new Date(this.$moment().add(-1, "days"));
      }
      if (
        this.selectedModalidadePrincipal &&
        this.selectedModalidadePrincipal.MaxFutureDays > 0
      ) {
        _from = new Date(
          this.$moment().add(
            this.selectedModalidadePrincipal.MaxFutureDays,
            "days"
          )
        );
      }

      return {
        to: _to,
        from: _from
      };
      return {};
    },

    disabledDatesFim() {
      //REGRA DESABILITADA
      let _to = undefined;
      let _from = undefined;
      const minPeriod =
        (this.selectedModalidadePrincipal || {}).MinPeriodInDays || 0;
      const maxPeriod =
        (this.selectedModalidadePrincipal || {}).MaxPeriodInDays || 0;
      if (minPeriod)
        _to = new Date(
          this.$moment(this.vigenciaInicioPrincipal).add(minPeriod, "days")
        );

      if (maxPeriod > 0)
        _from = new Date(
          this.$moment(this.vigenciaInicioPrincipal).add(maxPeriod, "days")
        );

      return {
        to: _to,
        from: _from
      };
    },
    disabledDatesInicioComplementar() {
      //REGRA DESABILITADA
      /****/
      let _to = undefined;
      let _from = undefined;
      if (
        this.selectedModalidadeComplementar &&
        this.selectedModalidadeComplementar.MaxRetroactivityDays > 0
      ) {
        _to = new Date(
          this.$moment().add(
            this.selectedModalidadeComplementar.MaxRetroactivityDays * -1,
            "days"
          )
        );
      } else {
        _to = new Date(this.$moment().add(-1, "days"));
      }
      if (
        this.selectedModalidadeComplementar &&
        this.selectedModalidadeComplementar.MaxFutureDays > 0
      ) {
        _from = new Date(
          this.$moment().add(
            this.selectedModalidadeComplementar.MaxFutureDays,
            "days"
          )
        );
      }

      return {
        to: _to,
        from: _from
      };
      return {};
    },
    disabledDatesFimComplementar() {
      //REGRA DESABILITADA
      let _to = undefined;
      let _from = undefined;
      const minPeriod =
        (this.selectedModalidadeComplementar || {}).MinPeriodInDays || 0;
      const maxPeriod =
        (this.selectedModalidadeComplementar || {}).MaxPeriodInDays || 0;
      if (minPeriod)
        _from = new Date(
          this.$moment(this.vigenciaInicioComplementar).add(minPeriod, "days")
        );

      if (maxPeriod > 0)
        _to = new Date(
          this.$moment(this.vigenciaInicioComplementar).add(maxPeriod, "days")
        );

      return {
        to: _to,
        from: _from
      };
    },

    calculoDiasVigenciaComplementar() {
      this.vigenciaDiasComplementar = this.calculoDataFim(
        this.vigenciaInicioComplementar,
        this.vigenciaFimComplementar,
        this.vigenciaDiasComplementar
      );
      this.calculoValorPremio(4);
    },

    calculoDataFimVigenciaComplementar() {
      if (this.vigenciaDiasComplementar < 0) {
        this.vigenciaDiasComplementar = undefined;
        this.$onpoint.errorModal("Prazo de dias não pode ser menor que zero.");
        return;
      }
      const minPeriod =
        (this.selectedModalidadeComplementar || {}).MinPeriodInDays || 0;
      const maxPeriod =
        (this.selectedModalidadeComplementar || {}).MaxPeriodInDays || 0;
      if (minPeriod > 0 && this.vigenciaDiasComplementar < minPeriod) {
        this.vigenciaDiasComplementar = undefined;
        this.$onpoint.errorModal(
          `Prazo de dias para essa modalidade não pode ser menor que ${minPeriod}.`
        );
        return;
      }

      if (maxPeriod > 0 && this.vigenciaDiasComplementar > maxPeriod) {
        this.vigenciaDiasComplementar = undefined;
        this.$onpoint.errorModal(
          `Prazo de dias para essa modalidade não pode ser maior que ${maxPeriod}.`
        );
        return;
      }

      this.vigenciaFimComplementar = this.calculoDias(
        this.vigenciaInicioComplementar,
        this.vigenciaFimComplementar,
        this.vigenciaDiasComplementar
      );

      this.calculoValorPremio(5);
    },

    calculoDataFimVigenciaPrincipal() {
      if (this.vigenciaDiasPrincipal < 0) {
        this.vigenciaDiasPrincipal = undefined;
        this.$onpoint.errorModal("Prazo de dias não pode ser menor que zero.");
        return;
      }
      const minPeriod =
        (this.selectedModalidadePrincipal || {}).MinPeriodInDays || 0;
      const maxPeriod =
        (this.selectedModalidadePrincipal || {}).MaxPeriodInDays || 0;

      if (minPeriod > 0 && this.vigenciaDiasPrincipal < minPeriod) {
        this.vigenciaDiasPrincipal = undefined;
        this.$onpoint.errorModal(
          `Prazo de dias para essa modalidade não pode ser menor que ${minPeriod}.`
        );
        return;
      }

      if (maxPeriod > 0 && this.vigenciaDiasPrincipal > maxPeriod) {
        this.vigenciaDiasPrincipal = undefined;
        this.$onpoint.errorModal(
          `Prazo de dias para essa modalidade não pode ser maior que ${maxPeriod}.`
        );
        return;
      }

      this.vigenciaFimPrincipal = this.calculoDias(
        this.vigenciaInicioPrincipal,
        this.vigenciaFimPrincipal,
        this.vigenciaDiasPrincipal
      );
      if (this.selectedModalidadePrincipal.EqualizeValidityDate) {
        this.garantirConsistenciaDeDatas();
      }
      this.calculoValorPremio(6);
    },

    garantirConsistenciaDeDatas() {
      this.updateProposta({
        ...this.proposta,
        ComplementaryStartDate: this.vigenciaFimPrincipal
      });
      //this.proposta.ComplementaryStartDate = this.vigenciaFimPrincipal;
    },

    desabilitaInicioVigenciaComplementar() {
      return (
        this.selectedModalidadePrincipal &&
        this.selectedModalidadePrincipal.EqualizeValidityDate
      );
    },

    calculoDiasVigenciaPrincipal() {
      this.vigenciaInicioComplementar = this.vigenciaFimPrincipal;

      this.vigenciaFimComplementar = this.calculoDias(
        this.vigenciaFimPrincipal,
        this.vigenciaFimComplementar,
        this.vigenciaDiasComplementar
      );
      console.log(
        "this.vigenciaFimComplementar: ",
        this.vigenciaFimComplementar
      );
      this.vigenciaDiasPrincipal = this.calculoDataFim(
        this.vigenciaInicioPrincipal,
        this.vigenciaFimPrincipal,
        this.vigenciaDiasPrincipal
      );
      this.calculoValorPremio(7);
    },

    calculoDias(dataInicio, dataFim, dias) {
      const inicio = this.$moment(dataInicio);

      if (inicio) {
        if (inicio && dias) {
          dataFim = new Date(inicio.add(dias, "days"));
          return dataFim;
        }
      }
    },

    calculoDataFim(dataInicio, dataFim, dias) {
      if (dataInicio === undefined || dataFim === undefined) return;
      const inicio = this.$moment(dataInicio);
      const fim = this.$moment(dataFim);

      if (inicio > fim) {
        this.showErroData = true;
        this.erroData =
          "Data de inicio da vigência não pode ser maior que o fim";
        return;
      }

      if (inicio) {
        if (inicio && fim) {
          const diasAbs = Math.abs(inicio.diff(fim, "days"));
          if (!isNaN(diasAbs)) {
            dias = diasAbs;
            return dias;
          }
        }
      }
    },
    getCondicoesMercado() {
      return axiosInstance
        .get(
          `/api/PolicyHolder/GetPolicyHolderMarketTerm?PolicyHolderUniqueId=${this.tomadorUniqueId}&modalityId=${this.selectedModalidadePrincipal.Id}`
        )
        .then(response => {
          this.condicoesMercado = response.data.Response;
        })
        .catch(errors => this.$onpoint.errorModal(errors.response.data.Errors));
    },
    async getCoberturas() {
      return await axiosInstance
        .get(
          `api/Core/GetAdditionalCoverages?ModalityUniqueId=${this.selectedModalidadePrincipal.UniqueId}&ignoreCache=true&ignoreDeleted=true`
        )
        .then(response => {
          this.coberturas = response.data.Response;
        })
        .catch(errors => this.$onpoint.errorModal(errors.response.data.Errors));
    },

    async getPercentageLimitPremiumRelationInsuredAmount() {
      return  await axiosInstance
        .get(`api/Core/GetPercentageLimitPremiumRelationIssuredAmount`)
        .then(response => {
          this.percLimit = response.data.Response;
        })
        .catch(errors => {
          this.$onpoint.errorModal(errors.response.data.Errors);
        });
    },

    async loadGetCompanyProposalTags() {
      return await axiosInstance
        .get("api/Proposal/GetCompanyProposalTags")
        .then(response => {
          this.tagsSeguradora = response.data.Response;
        });
    },

    getModalidadeComplementar(modalidadeUniqueId) {
      return axiosInstance
        .get(
          `/api/Core/GetComplementaryModalities?ModalityUniqueId=${modalidadeUniqueId}&ignoreCache=true&ignoreDeleted=true`
        )
        .then(response => {
          this.modalidadeComplementar = response.data.Response;
        })
        .catch(errors => this.$onpoint.errorModal(errors.response.data.Errors));
    },

   async getGrupoModalidades() {
      return await this.$onpoint.loading(async() => {
        return await axiosInstance
          .get(
            `/api/Core/GetModalityGroupsEnabledToInsured?InsuredUniqueId=${this.seguradoUniqueId}&PolicyHolderUniqueId=${this.tomadorUniqueId}`
          )
          .then(response => {
            this.modalidades = response.data.Response;
          })
          .catch(errors =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    },

    getModalidadePorGrupo(uniqueGrupoId) {
      return this.$onpoint.loading(() => {
        return axiosInstance
          .get(
            `/api/Core/GetModalities?ModalityGroupUniqueId=${uniqueGrupoId}&PolicyHolderUniqueId=${this.tomadorUniqueId}&InsuredUniqueId=${this.seguradoUniqueId}`
          )
          .then(response => {
            this.modalidadePrincipal = response.data.Response;
          })
          .catch(errors =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    },

   async getModalidadeObjeto(modalidadeId) {
      return await axiosInstance
        .get(`/api/Core/GetModalityObject?ModalityId=${modalidadeId}`)
        .then(response => {
          this.modalidadeObjeto = response.data.Response;
        })
        .catch(errors => this.$onpoint.errorModal(errors.response.data.Errors));
    },

   async getCoberturasAdicionaisObjeto() {
      let mapCoberturas = [];
      if (this.coberturasAdicionais && this.coberturasAdicionais.length > 0)
        mapCoberturas = this.coberturasAdicionais.map(item => {
          return item;
        });

      return await axiosInstance({
        method: "post",
        url: "/api/Core/GetAdditionalCoveragesObjects",
        data: mapCoberturas
      })
        .then(response => {
          this.coberturasAdicionaisObjeto = response.data.Response;
          // if(response.EnableToProposal) {
          //   this.coberturasAdicionaisObjeto = response.data.Response;
          // }
        })
        .catch(errors => this.$onpoint.errorModal(errors.response.data.Errors));
    },

    async modalidadeComplementarSelecionada(modalidadeComplementar) {
      this.cleanTag();
      this.modalidadeComplementarObjeto = {};

      this.updateProposta({
        ...this.proposta,
        ComplementaryModalityId: (modalidadeComplementar || {}).Id,
        ComplementaryModality: modalidadeComplementar
      });

      await this.getModalidadeObjetoComplementar(modalidadeComplementar);
    },
    async getModalidadeObjetoComplementar(modalidadeComplementar) {
      if (!(modalidadeComplementar || {}).Id) {
        this.importanciaSeguradaComplementar = undefined;
        this.vigenciaInicioComplementar = undefined;
        this.vigenciaDiasComplementar = undefined;
        this.vigenciaFimComplementar = undefined;
        this.modalidadeComplementarObjeto = {};
        return Promise.resolve(undefined);
      }

      if (this.selectedModalidadePrincipal.EqualizeValidityDate) {
        this.garantirConsistenciaDeDatas();
      }

      return await this.$onpoint.loading(async () => {
        return await axiosInstance
          .get(
            `/api/Core/GetModalityObject?ModalityId=${modalidadeComplementar.Id}`
          )
          .then(response => {
            this.modalidadeComplementarObjeto = response.data.Response;
          })
          .catch(errors =>
            this.$onpoint.errorModal(errors.response.data.Errors)
          );
      });
    },

    formatStrMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
      try {
        decimalCount = Math.abs(decimalCount);
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

        const negativeSign = amount < 0 ? "-" : "";

        const i = parseInt(
          (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
        ).toString();
        const j = i.length > 3 ? i.length % 3 : 0;

        return (
          negativeSign +
          (j ? i.substr(0, j) + thousands : "") +
          i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`) +
          (decimalCount
            ? decimal +
              Math.abs(amount - i)
                .toFixed(decimalCount)
                .slice(2)
            : "")
        );
      } catch (e) {}
    },

    grupoModalidadeSelecionado(grupo) {
      this.isDisabledEnd = true;
      this.isDisabledEndComplementary = true;
      this.isDisabledComplementary = true;
      this.importanciaSeguradaComplementar = undefined;
      this.vigenciaDiasComplementar = undefined;
      this.vigenciaDiasPrincipal = undefined;
      this.importanciaSegurada = undefined;
      this.vigenciaFimPrincipal = undefined;
      this.vigenciaInicioPrincipal = undefined;
      this.modalidadeComplementar = undefined;
      this.selectedModalidadePrincipal = undefined;
      this.selectedModalidadeComplementar = undefined;
      this.coberturas = [];
      this.modalidadeObjeto = undefined;
      this.vigenciaInicioComplementa = undefined;
      this.vigenciaFimComplementar = undefined;
      this.coberturasAdicionais = [];
      this.modalidadeObjeto = {};
      this.modalidadeComplementarObjeto = {};
      if (!grupo) {
        this.selectedGrupo = undefined;
        this.selectedModalidadePrincipal = undefined;
        this.modalidadePrincipal = [];
        this.updateProposta({
          ...this.proposta,
          ModalityGroupId: undefined,
          ModalityGroup: undefined,
          ModalityId: undefined,
          Modality: undefined
        });
        return new Promise((resolve, reject) => {
          resolve();
        });
      } else {
        this.updateProposta({
          ...this.proposta,
          ModalityGroupId: grupo.Id,
          ModalityGroup: grupo
        });
        return this.getModalidadePorGrupo(grupo.UniqueId);
      }
    },

    validarNumeros(evento, caminho) {
      const valoresNumericos = evento.replace(/\D/g, "");

      let target = this;
      const parts = caminho.split(".");
      for (let i = 0; i < parts.length - 1; i++) {
        target = target[parts[i]];
      }
      target[parts[parts.length - 1]] = valoresNumericos;
    }
  }
};
</script>

<style lang="scss" scoped>
.voltar-btn,
.voltar-btn:hover {
  border: 1px solid #0e65a5;
  background: transparent !important;
  color: #0e65a5;
  font-weight: 600;
}
.footer-postion {
  position: absolute;
  bottom: -70px;
  right: 30px;
}
::v-deep .vs-con-dropdown,
::v-deep .vs__dropdown-toggle {
  border-radius: 8px;
}

::v-deep .vdp-datepicker input {
  height: 47px;
}

p {
  i {
    color: rgba(var(--vs-warning), 1);
    font-size: 0.8em;
  }
}

aside {
  border-left: solid 1px rgba(0, 0, 0, 0.2);
}

.text-objeto {
  border: solid 1px rgba(0, 0, 0, 0.2);
  max-height: 300px;
  overflow-y: auto;
}

.particular-condition {
  ::v-deep .vs__dropdown-toggle {
    height: auto !important;
    min-height: 50px !important;
    padding: 10px !important;
    .vs__selected {
      width: auto !important;
    }
  }
}

.modalCancelation {
  z-index: 55000000 !important;
}
</style>
